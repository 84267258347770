div.main {
  display: grid;
  grid-gap: 10px 10px;
  grid-template-columns: 20% auto;
  grid-template-rows: auto auto;
}

div.header {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

div.login {
  float: right;
}

div.flags {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

div.flag-desc {
  font-size: 0.85em;
}

div.seeds {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

a {
  color: blue;
  cursor: pointer;
}

ul {
  list-style-type: none;
  padding-inline-start: 10px;
}

li {
  margin-bottom: 0.75em;
}

h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0 0;
}

h3 {
  font-size: 1.25em;
  font-weight: bold;
  margin-top: 0;
}

div.playthrough {
  margin-left: 10px;
  margin-top: 0.5em;
  margin-bottom: 0.75em;
  border-top: 1px dashed black;
}

div.playthrough:nth-child(2) {
  border-top: none;
}
